import { Typography } from "@mui/material";
import React from "react";

function HeaderComponent() {
  return (
    <div style={{ marginBottom: "32px" }}>
      <Typography variant="h4" sx={{ fontWeight: "bold", color: "white" }}>
        State Of Aviation
      </Typography>
      <Typography variant="caption" sx={{ color: "white" }}>
        A survey on the aviation industry
      </Typography>
    </div>
  );
}

export default HeaderComponent;
