import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import React, { useEffect } from "react";

function DialogShareNotSupported(props) {
  const handleCloseShareNotSupported = () => {
    props.setOpenShareNotSupported(false);
  };

  return (
    <Dialog
      open={props.openShareNotSupported}
      onClose={handleCloseShareNotSupported}
      aria-labelledby="alert-dialog-share-not-supported"
      aria-describedby="alert-dialog-share-not-supported"
    >
      <DialogTitle id="alert-dialog-title">{"Share not supported on this device"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Copy the following link to share:
          <br />
          <span style={{ background: "lightgrey", padding: "4px 8px", borderRadius: "4px" }}>
            https://stateofaviation.com
          </span>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseShareNotSupported}>close</Button>
      </DialogActions>
    </Dialog>
  );
}

export default DialogShareNotSupported;
