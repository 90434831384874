import { Button } from "@mui/material";
import React, { useState } from "react";
import ShareIcon from "@mui/icons-material/Share";
import DialogShareNotSupported from "./DialogShareNotSupported";

function ButtonShare() {
  const [openShareNotSupported, setOpenShareNotSupported] = useState(false);

  const handleClickShare = () => {
    window.gtag("event", "Share button clicked", {
      event_category: "engagement",
      event_label: "Share button clicked",
    });

    if (navigator.share) {
      navigator
        .share({
          title: "State Of Aviation",
          text: "A survey on the aviation industry",
          url: "https://stateofaviation.com",
        })
        .then(() => {
          window.gtag("event", "Share successful", {
            event_category: "engagement",
            event_label: "Share successful",
          });
          console.log("Share successful");
        })
        .catch((error) => {
          window.gtag("event", "Share failed", {
            event_category: "errors",
            event_label: "Share failed",
          });
          console.error("Error sharing", error);
        });
    } else {
      window.gtag("event", "Share not supported", {
        event_category: "errors",
        event_label: "Share not supported",
      });
      setOpenShareNotSupported(true);
      console.log("Share not supported");
    }
  };

  return (
    <>
      <Button
        variant="default"
        onClick={handleClickShare}
        endIcon={<ShareIcon />}
        sx={{
          color: "white",
          position: "fixed",
          bottom: "12px",
          right: "12px",
        }}
      >
        Share
      </Button>

      <DialogShareNotSupported
        openShareNotSupported={openShareNotSupported}
        setOpenShareNotSupported={setOpenShareNotSupported}
      />
    </>
  );
}

export default ButtonShare;
