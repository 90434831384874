import "./App.css";
import backgroundImage from "./assets/aircrafLow.jpg";
import ButtonShare from "./components/ButtonShare";
import HeaderComponent from "./components/HeaderComponent";
import EmailCaptureComponent from "./components/EmailCaptureComponent";
import AboutSection from "./components/DialogAbout";

export default function App() {
  return (
    <div
      style={{
        boxSizing: "border-box",
        height: "100vh",
        width: "100vw",
        display: "grid",
        justifyContent: "center",
        alignContent: "start",
        textAlign: "center",
        fontFamily: "roboto",
        background: `url(${backgroundImage})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div style={{ marginTop: "10vh", display: "grid", justifyItems: "center" }}>
        <HeaderComponent />
        <EmailCaptureComponent />
        <ButtonShare />
        <AboutSection />
      </div>
    </div>
  );
}
