import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { updateUser } from "../graphql/mutations";

function DialogEmailAdded(props) {
  const [suggestionText, setSuggestionText] = useState("");

  const handleCloseEmailAdded = () => {
    props.setShowDialogEmailAdded(false);
  };

  const handleAddSuggestion = async () => {
    try {
      await API.graphql({
        query: updateUser,
        variables: { input: { id: props.userId, suggestion: suggestionText } },
      });
      window.gtag("event", "Suggestion added", {
        event_category: "engagement",
        event_label: `Suggestion added: ${suggestionText}`,
      });
    } catch (error) {
      console.error("Error adding suggestion", error);
      window.gtag("event", "Suggestion add failed", {
        event_category: "errors",
        event_label: `Suggestion add failed, ${error.errors[0].message}`,
      });
    }

    setSuggestionText("");
    handleCloseEmailAdded();
  };

  return (
    <Dialog
      open={props.showDialogEmailAdded}
      onClose={handleCloseEmailAdded}
      aria-labelledby="alert-dialog-share-not-supported"
      aria-describedby="alert-dialog-share-not-supported"
    >
      <DialogTitle id="alert-dialog-title">{"Email Address added"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Thank you for your interest.
          <br />
          You will be notified when the survey is ready.
        </DialogContentText>
        <br />
        <DialogContentText id="alert-dialog-description" variant="caption">
          Would you like to suggest anything for the survey?
        </DialogContentText>
        <TextField
          id="outlined-multiline-static"
          label=""
          multiline
          minRows={2}
          value={suggestionText}
          onChange={(evt) => setSuggestionText(evt.target.value)}
          sx={{ width: "100%" }}
        />
      </DialogContent>
      <DialogActions>
        {suggestionText.length > 0 ? (
          <Button onClick={handleAddSuggestion} color="success">
            Submit suggestion
          </Button>
        ) : (
          <Button onClick={handleCloseEmailAdded}>close</Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default DialogEmailAdded;
