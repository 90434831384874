import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useState } from "react";

function AboutSection(props) {
  const [openDialogAbout, setOpenDialogAbout] = useState(false);

  const handleCloseDialogAbout = () => {
    setOpenDialogAbout(false);
  };

  return (
    <>
      <Typography
        onClick={() => setOpenDialogAbout(true)}
        variant="caption"
        sx={{ color: "white", cursor: "pointer", marginTop: "12px", position: "absolute", top: "12px", right: "24px" }}
      >
        What's this all about?
      </Typography>
      <Dialog
        open={openDialogAbout}
        onClose={handleCloseDialogAbout}
        aria-labelledby="alert-dialog-about"
        aria-describedby="alert-dialog-about"
      >
        <DialogTitle id="alert-dialog-title">{"What's this all about?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Professionally I'm a pilot but in my free time, I build web apps.
            <br />
            <br />
            Over the years I've been following a survey of software developers called "State of Javascript" (
            <a href="https://stateofjs.com" target="_blank">
              https://stateofjs.com
            </a>
            ) and thought this will be a really insightful project to build around the aviation industry.
            <br />
            <br />
            As times are changing with airlines pushing for single pilot ops, drones having more of an impact in the
            military world and the massive knocks aviation took during covid causing some pilots to completely change
            careers, I thought it will make for some interesting data...
            <br />
            <br />
            This is going to be a long-term project, so it's a slow burn effort of finding people who would be
            interested in adding some of their insights and opinions and hopefully we can all take something away from
            it.
            <br />
            <br />
            I am definitely handling the data in accordance with GDRP and PoPI data management practices, which means
            everyone will have full control over their own data and know exactly how it will be used. Be assured I won't
            be selling any email lists or anything.
            <br />
            <br />
            Feel free to participate if you're in the aviation industry or watch from the sidelines, whichever you're
            comfortable with. 🙂
            <br />
            <br />
            <br />
            Regards,
            <br />
            Bernhard
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialogAbout}>close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AboutSection;
