/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_appsync_graphqlEndpoint": "https://3byhkkwduvf7xo7hxsi4jw4dxi.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-d25tgstvwrb6nb2qp5bksrzvyu",
    "aws_cognito_identity_pool_id": "us-east-2:0357c3f2-f42a-426d-87c8-bb88a27c9bfc",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_3hptrtCED",
    "aws_user_pools_web_client_id": "32009cd0mr4ajiagv4mc2vcf7e",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
