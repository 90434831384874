import { Button, LinearProgress, TextField } from "@mui/material";
import { API } from "aws-amplify";
import React, { useState } from "react";
import { createUser } from "../graphql/mutations";
import { userByEmail } from "../graphql/queries";
import DialogEmailAdded from "./DialogEmailAdded";

function EmailCaptureComponent() {
  const [emailAddress, setEmailAddress] = useState("");
  const [userId, setUserId] = useState(null);
  const [helperText, setHelperText] = useState("");
  const [showProgress, setShowProgress] = useState(false);
  const [showDialogEmailAdded, setShowDialogEmailAdded] = useState(false);

  const handleChangeEmailAddress = (evt) => {
    setEmailAddress(evt.target.value);
  };

  const listenForEnterKey = (evt) => {
    if (evt.key === "Enter") {
      handleClickJoin();
    }
  };

  const handleClickJoin = async () => {
    if (!validateEmail()) {
      setHelperText("Please enter a valid email address");
    } else {
      resetHelperText();
      setShowProgress(true);
      let result = await checkEmailExists();

      if (result === "error") {
        setShowProgress(false);
        return;
      } else if (result !== undefined) {
        setHelperText("Email already exists");
      } else if (result === undefined) {
        let result = await captureEmail();
        if (result === "error") {
          setShowProgress(false);
          return;
        } else {
          setHelperText("");
          setEmailAddress("");
          setUserId(result.id);
          setShowDialogEmailAdded(true);
        }
      }
      setShowProgress(false);
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;

    if (emailRegex.test(emailAddress.trim().toLowerCase())) {
      console.log("Valid email");
      return true;
    } else {
      console.error("Invalid email");
      return false;
    }
  };

  const checkEmailExists = async (email) => {
    try {
      let result = await API.graphql({ query: userByEmail, variables: { email: emailAddress.trim().toLowerCase() } });
      let user = result.data.userByEmail.items[0];
      return user;
    } catch (error) {
      console.error("CUST_ERR: Error in checkEmailExists: ", error);
      setHelperText(`Error: ${error?.errors[0]?.message}`);
      return "error";
    }
  };

  const captureEmail = async () => {
    try {
      let result = await API.graphql({
        query: createUser,
        variables: { input: { email: emailAddress.trim().toLowerCase() } },
      });
      let user = result.data.createUser;
      window.gtag("event", "Email added", {
        event_category: "engagement",
        event_label: `Email added: ${emailAddress.trim().toLowerCase()}`,
      });
      return user;
    } catch (error) {
      console.error("CUST_ERR: Error in captureEmail: ", error);
      setHelperText(`Error: ${error?.errors[0]?.message}`);
      return "error";
    }
  };

  const resetHelperText = () => {
    helperText !== "" && setHelperText("");
  };

  return (
    <>
      <TextField
        id="email-address"
        variant="outlined"
        size="small"
        placeholder="Email address"
        label=""
        value={emailAddress}
        helperText={helperText}
        onKeyDown={listenForEnterKey}
        onChange={handleChangeEmailAddress}
        sx={{
          "& .MuiOutlinedInput-notchedOutline": { border: "none" },
          minWidth: "300px",
          "& .MuiFormHelperText-root": { color: "red" },
          maxWidth: "300px",
        }}
        inputProps={{ style: { background: "white", borderRadius: "90px" } }}
      />
      <div style={{ marginTop: "12px" }}>
        {showProgress && <LinearProgress sx={{ width: "100%" }} />}
        <Button
          // variant="outlined"
          disabled={showProgress}
          onClick={handleClickJoin}
          sx={{
            color: "white",
            justifySelf: "center",
            // border: "1px solid white",
          }}
        >
          Join the waitlist
        </Button>
      </div>
      <DialogEmailAdded
        showDialogEmailAdded={showDialogEmailAdded}
        setShowDialogEmailAdded={setShowDialogEmailAdded}
        emailAddress={emailAddress}
        userId={userId}
      />
    </>
  );
}

export default EmailCaptureComponent;
